var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Breadcrumbs', {
    staticClass: "grow-0"
  }), _vm._v(" "), _c('div', {
    staticClass: "h-[calc(100vh - 160px)] flex items-center justify-center dark:bg-worky-neutral-400"
  }, [_vm.error.statusCode === 403 ? _c('section', {
    staticClass: "flex justify-between gap-4"
  }, [_c('div', {
    staticClass: "font-20px flex flex-col justify-center"
  }, [_c('div', {
    staticClass: "text-40px"
  }, [_vm._v("Acceso restringido")]), _vm._v(" "), _vm._m(0), _vm._v(" "), _c('ZButton', {
    staticClass: "mt-[35px] w-fit",
    attrs: {
      "size": "lg"
    },
    on: {
      "onClick": function () {
        return _vm.$router.push('/');
      }
    }
  }, [_vm._v("\n          Ir al inicio\n        ")])], 1), _vm._v(" "), _c('img', {
    attrs: {
      "src": require("assets/images/403-Forbidden.svg")
    }
  })]) : _c('section', {
    staticClass: "flex justify-between"
  }, [_c('div', [_c('p', {
    staticClass: "text-blue-900 mb-4 text-center text-4xl font-bold"
  }, [_vm._v("\n          " + _vm._s(_vm.getMessageError.title) + "\n        ")]), _vm._v(" "), _c('p', {
    staticClass: "dark:text-blue-700 mb-4 text-center text-2xl font-bold"
  }, [_vm._v("\n          " + _vm._s(_vm.getMessageError.message) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center justify-center"
  }, [_c('NuxtLink', {
    staticClass: "bg-blue-900 hover:bg-blue-700 rounded-md p-2 font-bold text-worky-white",
    attrs: {
      "to": "/"
    }
  }, [_vm._v("\n            Ir a la página de inicio\n          ")])], 1)])])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-[35px] grid gap-20px"
  }, [_vm._v("\n          Lamentablemente, no tienes los permisos necesarios para acceder a\n          esta página.\n          "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("\n            Comunícate con tu administrador para solicitar los permisos.\n          ")])]);

}]

export { render, staticRenderFns }