<template>
  <div>
    <Breadcrumbs class="grow-0" />
    <div
      class="h-[calc(100vh - 160px)] flex items-center justify-center dark:bg-worky-neutral-400"
    >
      <section
        v-if="error.statusCode === 403"
        class="flex justify-between gap-4"
      >
        <div class="font-20px flex flex-col justify-center">
          <div class="text-40px">Acceso restringido</div>
          <div class="mt-[35px] grid gap-20px">
            Lamentablemente, no tienes los permisos necesarios para acceder a
            esta página.
            <span class="font-semibold">
              Comunícate con tu administrador para solicitar los permisos.
            </span>
          </div>
          <ZButton
            class="mt-[35px] w-fit"
            size="lg"
            @onClick="() => $router.push('/')"
          >
            Ir al inicio
          </ZButton>
        </div>
        <img src="~/assets/images/403-Forbidden.svg" />
      </section>
      <section v-else class="flex justify-between">
        <div>
          <p class="text-blue-900 mb-4 text-center text-4xl font-bold">
            {{ getMessageError.title }}
          </p>
          <p class="dark:text-blue-700 mb-4 text-center text-2xl font-bold">
            {{ getMessageError.message }}
          </p>
          <div class="flex items-center justify-center">
            <NuxtLink
              to="/"
              class="bg-blue-900 hover:bg-blue-700 rounded-md p-2 font-bold text-worky-white"
            >
              Ir a la página de inicio
            </NuxtLink>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ErrorLayout',
    components: {
      ZButton: () => import('~/components/ui/buttons/ZButton.vue'),
      Breadcrumbs: () => import('~/components/ui/breadcrumbs/Breadcrumbs.vue'),
    },
    props: {
      error: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        homePath:
          this.$authentication.getUserConfigByVariable('layouts')?.value === '1'
            ? '/layouts-app/'
            : '/',
      };
    },
    computed: {
      getMessageError() {
        if (this.error.statusCode === 404)
          return {
            title: '404 Página no encontrada',
            message: 'Favor de validar la url en la barra de direcciones.',
          };
        if (this.error.statusCode === 403)
          return {
            title: '401 No autorizado',
            message: 'Favor de validar sus permisos.',
          };
        return {
          title: `Ha ocurrido un error`,
          message: `Lo sentimos, ha ocurrido un error durante el proceso`,
        };
      },
    },
    methods: {
      routesName(name) {
        const names = {
          'route.home': 'Inicio',
        };
        return names[name] || name;
      },
    },
  };
</script>
<style></style>
