export default {
  methods: {
    async getData(
      url,
      inputName,
      showEmptyToast = true,
      showErrorToast = true,
    ) {
      const result = await this.$dataSource.getData(url);
      const { code = 1, message = '', data = {} } = result || {};

      if (code === 3) return data;

      if (code === 2) {
        if (showEmptyToast) {
          this.$toasts.createToast({
            message: 'Sin información.',
            description: `Sin datos de ${inputName}.`,
            type: 'alert',
          });
        }

        return [];
      }

      if (showErrorToast) {
        this.$toasts.createToast({
          message: 'No se pudo obtener la información.',
          description: message,
          type: 'error',
        });
      }

      return [];
    },
  },
};
