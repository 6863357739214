import { useAuthenticationStore } from '@/stores/authentication';

const commonHeaders = () => {
  return {};
};

const validateAuth = (app, res) => {
  const validURL = [
    '/cart-app/',
    '/cart-app/products/',
    '/cart-app/services/',
    '/cart-app/services/maquila/',
    '/cart-app/services/consulting/',
    '/login-cart/',
    '/login-cart/signup/',
    '/login-cart/recover-password/',
    '/cart-app/payment-process/',
    '/cart-app/purchase-summary/',
  ];

  if (validURL.includes(app?.context?.from?.path)) return;
  if (+res.status !== 401) return;

  const newToast = {
    message: 'Sesión expirada',
    description: 'Tu sesión ha expirado, esperamos verte pronto.',
    type: 'alert',
  };
  app.$toasts.createToast(newToast);
  app.$authentication.resetSession();
  const whiteLabel = localStorage.getItem('white-label') === 'true';
  const isLayoutsAppUser =
    app.$authentication.getUserConfigByVariable('layouts')?.value === '1';

  app.router.push(
    isLayoutsAppUser ? '/layouts-app/login/' : whiteLabel ? '/auth' : '/login/',
  );
  return true;
};

const responseOnError = (response) => [500].includes(response.status);

const defaultErrorResponse = {
  code: 1,
  message: 'No se pudo completar la operación, intenta más tarde.',
  data: [],
};

export default class DataSource {
  constructor(app) {
    this.$auth = useAuthenticationStore();
    this.$app = app;
    this.baseUrl = process.env.DATA_SOURCE;
  }

  async postData(data, srcUrl) {
    const url = `${this.baseUrl}${srcUrl}`;
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...commonHeaders(),
        },
        body: JSON.stringify(data),
      }).then((res) => res.json());
    } catch (err) {
      return err;
    }
  }

  async postDataParams(data, srcUrl, type = 'json', authToken) {
    const token = authToken || this.$auth.getSession?.token || '';
    const endpoint = `${this.baseUrl}${srcUrl}`;

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
          ...commonHeaders(),
        },
        body: JSON.stringify(data),
      });

      if (responseOnError(response)) return defaultErrorResponse;
      if (validateAuth(this.$app, response)) return;

      if (
        type === 'json' ||
        response.headers.get('content-type') === 'application/json'
      ) {
        return response.json();
      }

      if (type === 'text') return response.text();
      if (type === 'blob') return response.blob();

      return defaultErrorResponse;
    } catch (error) {
      console.error(error);
      return defaultErrorResponse;
    }
  }

  async updateData(data, srcUrl, method = 'PUT') {
    const { token } = this.$auth.getSession;
    const url = `${this.baseUrl}${srcUrl}`;
    try {
      return await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
          ...commonHeaders(),
        },
        body: JSON.stringify(data),
      }).then((res) => {
        if (validateAuth(this.$app, res)) {
          return;
        }

        if (responseOnError(res)) {
          return defaultErrorResponse;
        }

        return res.json();
      });
    } catch (err) {
      return err;
    }
  }

  async patchData(data, srcUrl) {
    const { token } = this.$auth.getSession;
    const url = `${this.baseUrl}${srcUrl}`;
    try {
      return await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
          ...commonHeaders(),
        },
        body: JSON.stringify(data),
      }).then((res) => {
        if (validateAuth(this.$app, res)) {
          return;
        }

        if (responseOnError(res)) {
          return defaultErrorResponse;
        }

        return res.json();
      });
    } catch (err) {
      return err;
    }
  }

  async save(srcUrl, data) {
    const { token } = this.$auth.getSession;
    const eolBackslash = /\/$/;
    const editable = data?.id ? `${data.id}/` : '';
    const add = eolBackslash.test(srcUrl) ? editable : `/${editable}`;
    const url = `${this.baseUrl}${srcUrl}${add}`;

    const dataFormmated = data?.id ? data : Array.isArray(data) ? data : [data];
    if (!data?.id && !Array.isArray(dataFormmated)) {
      delete data.id;
      delete data.isActive;
    }

    try {
      return await fetch(url, {
        method: data.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
          ...commonHeaders(),
        },
        body: JSON.stringify(dataFormmated),
      }).then((res) => {
        if (validateAuth(this.$app, res)) {
          return;
        }

        if (responseOnError(res)) {
          return defaultErrorResponse;
        }

        return res.json();
      });
    } catch (err) {
      return err;
    }
  }

  async getData(srcUrl, authToken, params = { format: 'json' }) {
    const url = `${this.baseUrl}${srcUrl}`;
    const token = authToken || this.$auth.getSession?.token;
    const authorization = token ? `JWT ${token}` : '';
    try {
      return await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: authorization,
          ...commonHeaders(),
        },
      }).then((res) => {
        if (responseOnError(res)) {
          return defaultErrorResponse;
        }

        if (validateAuth(this.$app, res)) return;
        return params.format === 'json' ? res.json() : res.text();
      });
    } catch (err) {
      return err;
    }
  }

  async getPublicData(srcUrl) {
    const url = `${this.baseUrl}${srcUrl}`;
    try {
      return await fetch(url, {
        method: 'GET',
        headers: {},
      }).then((res) => {
        if (!res?.ok) {
          return defaultErrorResponse;
        }

        return res.json();
      });
    } catch (err) {
      return err;
    }
  }

  async getDocument(srcUrl) {
    const url = `${this.baseUrl}${srcUrl}`;
    const { token } = this.$auth.getSession;
    try {
      return await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          ...commonHeaders(),
        },
      }).then((res) => {
        if (validateAuth(this.$app, res)) {
          return;
        }

        if (responseOnError(res)) {
          return defaultErrorResponse;
        }

        res.text();
      });
    } catch (err) {
      return err;
    }
  }

  async getPdf(srcUrl) {
    const url = `${this.baseUrl}${srcUrl}`;
    const { token } = this.$auth.getSession;
    let result = null;
    try {
      result = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          ...commonHeaders(),
        },
      }).then((res) => {
        if (responseOnError(res)) {
          return defaultErrorResponse;
        }

        return res;
      });
    } catch (err) {
      return err;
    }

    if (validateAuth(this.$app, result)) return;

    if (result.ok) return result.blob();

    if (result?.headers?.get('content-type') !== 'application/json') return;

    const { message = '' } = (await result?.json()) || {};

    const newToast = {
      message: 'Error al descargar',
      description: message || 'No se pudo completar la operación.',
      type: 'error',
    };
    this.$app.$toasts.createToast(newToast);
  }

  async getDataById(srcUrl) {
    const url = `${this.baseUrl}${srcUrl}`;
    const { token } = this.$auth.getSession;
    try {
      return await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${token}`,
          ...commonHeaders(),
        },
      }).then((res) => {
        if (responseOnError(res)) {
          return defaultErrorResponse;
        }

        if (validateAuth(this.$app, res)) return;
        return res.json();
      });
    } catch (err) {
      return err;
    }
  }

  async deleteById(srcUrl) {
    const url = `${this.baseUrl}${srcUrl}`;
    const { token } = this.$auth.getSession;
    try {
      return await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `JWT ${token}`,
          ...commonHeaders(),
        },
      }).then((res) => {
        if (responseOnError(res)) {
          return defaultErrorResponse;
        }

        if (validateAuth(this.$app, res)) return;
        return res.json();
      });
    } catch (err) {
      return err;
    }
  }

  async postFormData(formData, srcUrl) {
    const { token } = this.$auth.getSession;
    const url = `${this.baseUrl}${srcUrl}`;

    const headers = {
      Authorization: `JWT ${token}`,
      ...commonHeaders(),
    };

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers,
        body: formData,
      });
      if (validateAuth(this.$app, res)) {
        return;
      }

      if (responseOnError(res)) {
        return defaultErrorResponse;
      }

      return await res.json();
    } catch (err) {
      return err;
    }
  }

  async updateFormData(formData, srcUrl) {
    const { token } = this.$auth.getSession;
    const url = `${this.baseUrl}${srcUrl}`;

    const headers = {
      Authorization: `JWT ${token}`,
      ...commonHeaders(),
    };

    try {
      const res = await fetch(url, {
        method: 'PUT',
        headers,
        body: formData,
      });
      if (responseOnError(res)) {
        return defaultErrorResponse;
      }

      if (validateAuth(this.$app, res)) return;
      return await res.json();
    } catch (err) {
      return err;
    }
  }
}
