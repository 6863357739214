import Dexie from 'dexie';

export default {
  data() {
    return {
      db: null,
    };
  },
  created() {
    const currentVersionZ = this.$platform?.getVersionZentric() || '';
    const version = currentVersionZ
      ? currentVersionZ?.replace(/\./gi, '')
      : '9';
    this.db = new Dexie(`postalCodes_${version}`);
    this.db.version(version).stores({
      postalCodes: 'id,key, state_id, municipality_id, locality_id',
    });
  },
  methods: {
    async getPostalCodeId(postalCodeKey) {
      const postalCode = await this.db.postalCodes
        .where('key')
        .equals(postalCodeKey)
        .toArray();

      if (postalCode.length > 0) return postalCode.at(0).id;
      const response = await this.$dataSource.getData(
        `catalogs/postal-codes-by-key/${postalCodeKey}/`,
      );

      if (response.code === 3 && response.data.length) {
        this.db.postalCodes.put({
          id: response.data.at(0).id,
          key: postalCodeKey,
          state_id: response.data.at(0).state_id,
          municipality_id: response.data.at(0).municipality_id,
          locality_id: response.data.at(0).locality_id,
        });

        return response.data.at(0).id;
      } else {
        return 0;
      }
    },
  },
};
