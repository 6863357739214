export default class FormValidations {
  isValidEmail(value) {
    // Al menos una mayúscula letra en inglés , (?=.*?[A-Z])
    // Al menos una letra minúscula en inglés, (?=.*?[a-z])
    // Al menos un dígito, (?=.*?[0-9])
    // Al menos un carácter especial, (?=.*?[#?!@$%^&*-])
    // Mínimo ocho en longitud .{8,}
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(value);
  }

  isValidFolio(value) {
    const regex = /^[A-ZÑ0-9]{1,8}$/;
    return regex.test(value);
  }

  isValidEmailOrEmpty(value) {
    if (!value) return true;
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(value);
  }

  isValidPassword(value) {
    /*  Grupo 1: Aseguramos que sean 12 caracteres y que no contenga ><_- o salto de linea
        Grupo 2: Aseguramos que haya por lo menos una mayuscula
        Grupo 3: Aseguramos que haya por lo menos una minuscula
        Grupo 4: Aseguramos que haya por lo menos un número
        Grupo 5: Validamos que contenga al menos un caracter especial distinto de  ><_- o salto de linea */
    const regex =
      /(?=^([^>_<\-\n]{12,})$)(?=.*?[A-Z]+)(?=.*?[a-z]+)(?=.*?[0-9]+)(?=.*?[^a-zA-Z0-9>_<\-\n]+)/;
    return regex.test(value);
  }

  onlyNumbersLength(value, arrLength) {
    if (!value) return false;
    if (!Array.isArray(arrLength)) return false;
    return arrLength.includes(value.length);
  }

  hasOnlyNumbers(value) {
    const regex = /^[0-9]+$/;
    return regex.test(value);
  }

  isValidMobile(value) {
    // Valida que los 10 caracteres no sean iguales
    const noRepeat =
      /0{10}|1{10}|2{10}|3{10}|4{10}|5{10}|6{10}|7{10}|8{10}|9{10}/;
    // Valida que solo sean números y que sean 10
    const onlyNumbers = /^\S[0-9]{9}$/;
    return !noRepeat.test(value) && onlyNumbers.test(value);
  }

  isValidMobileOrEmpty(value) {
    // Valida que los 10 caracteres no sean iguales
    const noRepeat =
      /0{10}|1{10}|2{10}|3{10}|4{10}|5{10}|6{10}|7{10}|8{10}|9{10}/;
    // Valida que solo sean números y que sean 10
    const onlyNumbers = /^\S[0-9]{9}$|^$/;
    return !noRepeat.test(value) && onlyNumbers.test(value);
  }

  hasValue(value) {
    if (!value) return false;
    const result = value + ''.trim() === '';
    return !result;
  }

  isValidText(value) {
    const regex = /^[A-Z a-zÑñáéíóúÁÉÍÓÚ.\s]+$/;
    return regex.test(value);
  }

  isValidAccountName(value) {
    const regex =
      /^[A-Z\u00D1ÁÉÍÓÚÄËÏÖÜ][a-z\u00F1áéíóúäëïöü]*((\s[A-Z\u00D1ÁÉÍÓÚÄËÏÖÜ][a-z\u00F1áéíóúäëïöü]{1,})+)?$/;
    return regex.test(value);
  }

  isValidAccountNameOrEmpty(value) {
    if (value === '') return true;
    const regex =
      /^[A-Z\u00D1ÁÉÍÓÚÄËÏÖÜ][a-z\u00F1áéíóúäëïöü]*((\s[A-Z\u00D1ÁÉÍÓÚÄËÏÖÜ][a-z\u00F1áéíóúäëïöü]{1,})+)?$/;
    return regex.test(value);
  }

  isValidName(value) {
    const regex = /^[A-ZÜÑ.\s]{1,50}$/;
    return regex.test(value);
  }

  isValidNameOrEmpty(value) {
    const regex = /^[A-Z ÑÜ.]+$|^$/;
    return regex.test(value);
  }

  isValidEmployeeName(value) {
    return /^[A-ZÜÑ'´.\s-]+$/u.test(value);
  }

  isValidEmployeeNameOrEmpty(value) {
    if (!value) return true;
    return /^[A-ZÜÑ'´.\s-]+$/u.test(value);
  }

  isValidTextSpaceNumber(value) {
    const regex = /^[A-Za-zÑñáéíóúÁÉÍÓÚ0-9.\s]+$/;
    return regex.test(value);
  }

  isValidTextSpaceNumberOrEmpty(value) {
    const regex = /^[A-Za-zÑñáéíóúÁÉÍÓÚ0-9.\s]+$|^$/;
    return regex.test(value);
  }

  isValidTextOrEmpty(value) {
    const regex = /^[A-Z a-zÑñáéíóúÁÉÍÓÚ.]+$|^$/;
    return regex.test(value);
  }

  isValidNumberOrEmpty(value) {
    const regex = /^[0-9]+$|^$/;
    return regex.test(value);
  }

  isValidTextNumberOrEmpty(value) {
    const regex = /^[A-Z a-zÑñáéíóúÁÉÍÓÚ.0-9]+$|^$/;
    return regex.test(value);
  }

  isValidTextNumber(value) {
    const regex = /^[A-Za-zÑñáéíóúÁÉÍÓÚ0-9]+$/;
    return regex.test(value);
  }

  isValidRfc(value) {
    // PERSONA FISICA
    const regxFP =
      /([A-ZÑ&]{4})([0-9]{2})([0][1-9]|[1][0-2])([0][1-9]|[1][0-9]|[2][0-9]|[3][0-1])([A-Z0-9]{3})/;
    // MORAL
    const regxMP =
      /([A-ZÑ&]{3})([0-9]{2})([0][1-9]|[1][0-2])([0][1-9]|[1][0-9]|[2][0-9]|[3][0-1])([A-Z0-9]{3})/;

    return regxFP.test(value) || regxMP.test(value);
  }

  isValidCurp(value) {
    const regex =
      /^[A-Z]{1}[A-Z]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;
    return regex.test(value);
  }

  isExpirationFormat(value) {
    const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    return regex.test(value);
  }

  notSpaces(value) {
    const regex = /[ *]/;
    return !regex.test(value);
  }

  isValidEmployerRegistry(value) {
    const regex = /[A-Z]{1}[0-9]{10}/;
    const regexNumbers = /[0-9]{11}/;
    return regex.test(value) || regexNumbers.test(value);
  }

  isValidClabe(value) {
    const regex = /^[0-9]{18}$/;
    return regex.test(value);
  }

  isValidClabeOrEmpty(value) {
    const regex = /^[0-9]{18}$|^$/;
    return regex.test(value);
  }

  isValidBankAccount(value) {
    const regex = /^[0-9]{9,12}$/;
    return regex.test(value);
  }

  isValidBankAccountOrEmpty(value) {
    const regex = /^(?:\d{9,12})?$/;
    return regex.test(value);
  }

  isValidCardNumber(value) {
    const regex = /^[0-9]{15,16}$/;
    return regex.test(value);
  }

  isValidCardNumberOrEmpty(value) {
    const regex = /^[0-9]{15,16}$|^$/;
    return regex.test(value);
  }

  isValidCvc(value) {
    const regex = /^[0-9]{3,4}$/;
    return regex.test(value);
  }

  isValidPostalCode(value) {
    const regex = /^[0-9]{5}$/;
    return regex.test(value);
  }

  isValidPostalCodeOrEmpty(value) {
    const regex = /^[0-9]{5}$|^$/;
    return regex.test(value);
  }

  isValidDecimalNumber(value) {
    const regex = /^([0-9]{1,12}(\.[0-9]{0,6})?)$/;
    return regex.test(value);
  }

  isValidPercentageOrEmpty(value) {
    return String(value).length >= 0 || (value >= 0 && value <= 1);
  }

  isValidRiskValue(value) {
    const regex = /^([0-9]{1,6}(\.[0-9]{0,12})?)$/;
    return regex.test(value);
  }

  isValidPercentage(value) {
    return String(value).length && value >= 0 && value <= 1;
  }

  isValidTextNumbersSpacesSpecialChars(value) {
    const regex = /^[A-Za-zÁÉÍÓÚáéíóúÑñ0-9#¿?¡!@$%^&+-_ .,]+$/;
    return regex.test(value);
  }

  isValidExternalNumber(value) {
    const regex = /^([A-Za-z0-9 #&:;°.,+]){1,14}$/;
    return regex.test(value);
  }

  isValidExternalNumberOrEmpty(value) {
    const regex = /^([A-Za-z0-9 #&:;°.,+]){1,14}$|^$/;
    return regex.test(value);
  }

  isValidKey(value) {
    const regex = /^([A-Za-z0-9#¿?¡!@$%^&+-_.,]){3,5}$/;
    return regex.test(value);
  }

  isValidSsn(value) {
    const regex = /^[0-9]{11}$/;
    return regex.test(value);
  }

  isValidFormula(value) {
    const regex = /^[A-Za-zñÑ0-9%=*/+-.,()[\]<>']+$/;
    return regex.test(value);
  }

  startsWith(value) {
    return value.startsWith('=');
  }

  hasCorrectBrackets(value) {
    const valueArr = value.split('');
    let openParent = 0;
    let closeParent = 0;
    let openSquare = 0;
    let closeSquare = 0;

    valueArr.forEach((element) => {
      if (element === '(') openParent++;
      if (element === ')') closeParent++;
      if (element === '[') openSquare++;
      if (element === ']') closeSquare++;
    });

    return openParent === closeParent && openSquare === closeSquare;
  }

  isValidAmount(value) {
    const regex = /^\d+(\.\d{1,6})?$/;
    return regex.test(value);
  }

  isValidLengthService(value) {
    const regex = /^\d{1,2}(\.\d{1,3})?$/;
    return regex.test(value);
  }

  isValidVacationAllowances(value) {
    const regex = /^\d{1}(\.\d{1,4})?$/;
    return regex.test(value);
  }

  isValidIntegratingFactor(value) {
    const regex = /^\d{1}(\.\d{1,6})?$/;
    return regex.test(value);
  }

  isValidAmountOrEmpty(value) {
    const regex = /^\d+(\.\d{1,6})?$|^$/;
    return regex.test(value);
  }

  isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  isValidAccountingAccount(value) {
    const regex = /^[A-Za-z0-9- ]*$/;
    return regex.test(value);
  }

  isValidURL(value) {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
    return regex.test(value);
  }
}
