/**
 * Middleware para verificar si el usuario tiene permiso para acceder a una ruta.
 * @param {Object} context - El contexto de la aplicación Nuxt.
 * @param {Function} context.app - El objeto de la aplicación Nuxt.
 * @param {Function} context.redirect - La función de redirección de Nuxt.
 * @param {Object} context.route - La ruta actual de Nuxt.
 */

// Para el atributo de los permisos se usan los siguientes:
// - Create
// - Read
// - Update
// - Delete
// - BulkUpdate
// - BulkCreate

export default function ({ app, redirect, route, store, env, error }) {
  const { pathPermissions = null, hidden = false } = route.meta?.at(-1) || {};

  if (hidden) {
    error({ statusCode: 404, message: '' });
    return;
  }

  let isAllowed = false;

  // TODO: BORRAR LA SEGUNDA CONDICIÓN DEL IF ANTES DE MANDAR A DEV
  // NOTE: Podría valorarse que se quede la segunda condición para que se pueda acceder a las rutas sin permisos en caso de las no definidas

  const { support_token: isControlledAccount } =
    app.$authentication.getSession() ?? {};
  const staffAccount = app.$authentication.isStaff();
  const isOwner = app.$authentication.isOwner();
  if (
    !pathPermissions ||
    (Array.isArray(pathPermissions.permissions) &&
      !pathPermissions.permissions.length)
  ) {
    isAllowed = true; // Si no hay permisos definidos, se permite el acceso
  } else if (
    pathPermissions?.onlyStaff &&
    (staffAccount || isControlledAccount)
  ) {
    isAllowed = true;
  } else if (
    pathPermissions?.onlyStaff &&
    !staffAccount &&
    !isControlledAccount
  ) {
    isAllowed = false;
  } else if (isOwner) {
    isAllowed = true;
  } else if (Array.isArray(pathPermissions.permissions)) {
    isAllowed = app.$permissions.hasPermissions(pathPermissions.permissions);
  } else {
    const isUpdate = !!route.params[pathPermissions.param];
    const actionPermissions = isUpdate
      ? pathPermissions.permissions.update
      : pathPermissions.permissions.create;
    isAllowed = app.$permissions.hasPermissions(actionPermissions);
  }

  if (!isAllowed) {
    if (pathPermissions.redirectUrl) {
      redirect(pathPermissions.redirectUrl);
    } else {
      error({ statusCode: 403, message: 'Forbidden' });
    }
  }
}
