import { useListsStore } from '~~/stores/lists';

export default class Calendars {
  constructor() {
    this.lists = useListsStore();
  }

  getBulkUpdatePath() {
    return this.lists.getBulkUpdatePath;
  }

  getListPath() {
    return this.lists.getListPath;
  }

  getData() {
    return this.lists.getData;
  }

  getRowsSelected() {
    return this.lists.getRowsSelected;
  }

  getCurrentState() {
    return this.lists.getCurrentState;
  }

  setBulkUpdatePath(path) {
    this.lists.setBulkUpdatePath(path);
  }

  setListPath(path) {
    this.lists.setListPath(path);
  }

  setData(data) {
    this.lists.setData(data);
  }

  setRowsSelected(rowsSelected) {
    this.lists.setRowsSelected(rowsSelected);
  }

  setCurrentState(state) {
    this.lists.resetCurrentState();
    this.lists.setCurrentState(state);
  }

  resetCurrentState() {
    this.lists.resetCurrentState();
  }
}
