export default {
  methods: {
    /**
     * Logs in a user with the provided username and password.
     *
     * @param {string} username - The username of the user.
     * @param {string} password - The password of the user.
     * @param {string} redirectPath - The path to redirect to after successful login.
     * @return {Object} An object indicating whether the login was successful and a message.
     */
    async login(loginData = {}, redirectPath) {
      const {
        code = 1,
        message = 'Error al iniciar sesión',
        data = {},
      } = await this.$dataSource.postData(loginData, 'users/login');

      if (code !== 3) {
        this.resultToast({
          unlockSession: !redirectPath,
          type: 'error',
          message,
        });
        return {
          successfull: false,
          message,
        };
      }

      localStorage.setItem('zentric-idle', false);
      localStorage.setItem('zentric-tickets', false);

      this.$authentication.setSession(data);
      if (redirectPath) {
        this.validateTicketsApp();
        await this.setLogRocketSession({ userData: data?.user || {} });
        localStorage.setItem('zentric-path', redirectPath);
        this.loading = false;
        this.$router.push(redirectPath);
      }

      const isWhiteLabel = localStorage.getItem('white-label') === 'true';

      this.resultToast({
        unlockSession: !redirectPath,
        isWhiteLabel,
        type: 'success',
      });

      return {
        successfull: true,
        message,
      };
    },
    /**
     * Validates the tickets app.
     *
     * This function checks if the current route path includes the word 'tickets'. If it does, it sets a flag in the local storage.
     *
     * @return {void}
     */
    validateTicketsApp() {
      const { path: loginRoute } = this.$route || {};
      if (loginRoute?.includes('tickets')) {
        localStorage.setItem('zentric-tickets', true);
      }
    },
    /**
     * Sets the LogRocket session with the provided user data.
     *
     * @param {Object} userData - The user data to set the session with.
     * @param {string} userData.first_name - The first name of the user.
     * @param {string} userData.last_name - The last name of the user.
     * @param {string} userData.second_surname - The second surname of the user.
     * @param {string} userData.email - The email of the user.
     * @param {string} userData.username - The username of the user.
     * @return {void}
     */
    async setLogRocketSession({ userData = {} }) {
      try {
        const {
          first_name: firstName,
          last_name: lastName,
          second_surname: secondSurname,
          email,
          username,
        } = userData;
        const name = `${firstName} ${lastName} ${secondSurname}`;
        const sessionDetail = {
          name,
          email,
          username,
        };
        await this.$logRocket.identify(username, sessionDetail);
      } catch (error) {
        console.error('Error setting LogRocket session:', error);
        // nothing to do
      }
    },
    /**
     * Generates a toast message based on the provided parameters.
     *
     * @param {object} options - The options for generating the toast message.
     * @param {boolean} options.unlockSession - Indicates whether to unlock the session.
     * @param {boolean} options.isWhiteLabel - Indicates whether it is a white label.
     * @param {string} options.type - The type of the toast message.
     * @param {string} options.message - The message content of the toast.
     * @return {object} The generated toast message.
     */
    resultToast({
      unlockSession = false,
      isWhiteLabel = false,
      type = 'success',
      message = '',
    }) {
      if (type === 'error') {
        return this.$toasts.createToast({
          message: unlockSession
            ? 'Error al desbloquear sesión'
            : 'Error al iniciar sesión',
          description: message,
          type: 'error',
        });
      }

      return this.$toasts.createToast({
        message: unlockSession
          ? 'Sesión desbloqueada'
          : `¡Te damos la bienvenida${isWhiteLabel ? '!' : ' a Worky Nómina!'}`,
        description: unlockSession
          ? `Puedes continuar disfrutando ${isWhiteLabel ? 'de tu sistema de nómina' : 'de Worky Nómina!'}.`
          : `No te pierdas las novedades de tu cuenta.`,
        type: 'success',
      });
    },
  },
};
