import { useCalendarsStore } from '~~/stores/calendars';
export default class Calendars {
  constructor() {
    this.calendars = useCalendarsStore();
  }

  getCalendarData() {
    return this.calendars.getCalendarData;
  }

  setCalendarData(value) {
    this.calendars.setCalendarData(value);
  }
}
